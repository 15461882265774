import React from 'react';
import start1Waifu from "../../assets/vector/startWaifu1.svg"
import start2Waifu from "../../assets/vector/startWaifu2.svg"
import start3Waifu from "../../assets/vector/startWaifu3.svg"
import start4Waifu from "../../assets/vector/startWaifu4.svg"
import start5Waifu from "../../assets/vector/startWaifu5.svg"
export default function FilterRarityWaifu({ selectStartWaifu, handleFilterStartWaifu }) {
    return <div>
        <h3 className="pb-2 text-lg text-white text-center md:text-right"> <strong>Filter by Waifu Rarity:</strong>  </h3>
        <div className="flex items-center">
            <button onClick={() => { handleFilterStartWaifu("all") }} className={`${selectStartWaifu == "all" ? " bg-primaryDark " : "bg-background"} w-10  rounded-md text-white h-9   ml-2 active:scale-90`}>All</button>
            <img onClick={() => { handleFilterStartWaifu("1") }} src={start1Waifu} alt="start-1" className={`${selectStartWaifu == "1" ? "w-9" : "w-8"} ml-2 cursor-pointer hover:scale-110 active:scale-90`} />
            <img onClick={() => { handleFilterStartWaifu("2") }} src={start2Waifu} alt="start-2" className={`${selectStartWaifu == "2" ? "w-9" : "w-8"} ml-2 cursor-pointer hover:scale-110 active:scale-90`} />
            <img onClick={() => { handleFilterStartWaifu("3") }} src={start3Waifu} alt="start-3" className={`${selectStartWaifu == "3" ? "w-9" : "w-8"} ml-2 cursor-pointer hover:scale-110 active:scale-90`} />
            <img onClick={() => { handleFilterStartWaifu("4") }} src={start4Waifu} alt="start-4" className={`${selectStartWaifu == "4" ? "w-9" : "w-8"} ml-2 cursor-pointer hover:scale-110 active:scale-90`} />
            <img onClick={() => { handleFilterStartWaifu("5") }} src={start5Waifu} alt="start-5" className={`${selectStartWaifu == "5" ? "w-9" : "w-8"} ml-2 cursor-pointer hover:scale-110 active:scale-90`} />
        </div>
    </div>;
}
