import * as React from "react";
import Layout from "../components/layouts/Layout";
import Crews from "../components/Crews/Crews";
import backgroundHex from "../assets/img/backgroundSections/background.webp";

export default function fleets() {
    return (
        <Layout background={backgroundHex}>
            <Crews />
        </Layout>
    )
}