import React, { useState, useContext, useEffect } from 'react'
import UserContext from '../../context/userContext/UserContext';
import ModalX from '../elements/ModalX';
import CrewsModal from './CrewsModal'
import CrewsModalEdit from './CrewsModalEdit'
import CrewCard from "../../components/CrewCard"
import Modal from '../elements/Modal';
import ListMiniCards from '../elements/miniWaifus';
import Icons from '../../assets/vector/icons';

import cottonIcon from "../../assets/img/claimIcons/cottonIcon.svg";
import woolIcon from "../../assets/img/claimIcons/woolIcon.svg";
import leatherIcon from "../../assets/img/claimIcons/leatherIcon.svg";
import leaveIcon from "../../assets/img/claimIcons/leaveIcon.svg";
import flaxIcon from "../../assets/img/claimIcons/flaxIcon.svg";
import silkIcon from "../../assets/img/claimIcons/silkIcon.svg";


export default function Crews() {
    const [modalEdit, setModalEdit] = useState(false);
    const [modal, setModal] = useState(false);

    const [reloadData, setReloadData] = useState(false)


    const { contract, account, chainId } = useContext(UserContext);
    const [listItems, setListItems] = useState(null)

    const [licenseModal, setLicenseModal] = useState(false);
    const [licenceInput, setLicenceInput] = useState("7");
    const [pricePeach, setPricePeach] = useState({ week: 7, biweekly: 13, month: 26 });
    const [arrayIcon] = useState([cottonIcon, woolIcon, leatherIcon, leaveIcon, flaxIcon, silkIcon])



    useEffect(() => {

        if (!modal || !modalEdit) setReloadData(true)

    }, [modal, modalEdit, licenseModal])

    useEffect(() => {
        if (contract && account && reloadData) {


            setReloadData(false)
            setListItems([])
            contract.ContractCrew.methods.getFormationIdsOf(account).call().then(result => {
                const getAllCrews = []
                const tempCrews = []
                result.map((element, index) => { if (element !== "1000000000000000000") return getAllCrews.push(contract.ContractCrew.methods.formations(element).call().then(result => tempCrews[index] = { ...result, id: element })) })
                Promise.all(getAllCrews).then(result => setListItems(tempCrews.reverse())).catch(err => setListItems([]))
            })
        }
    }, [contract, account, reloadData]);


    return (
        <div className="px-4 py-2">
            <ModalX id="fleetsModal" view={modal} action={setModal} bg={true} btn_close={true}><CrewsModal modal={modal} setModal={setModal} /></ModalX>
            <ModalX id="fleetsModal" view={modalEdit} action={setModalEdit} bg={true} btn_close={true}> <CrewsModalEdit modal={modalEdit} setModal={setModalEdit} />  </ModalX>

            {licenseModal && <Modal screen={true} modal={licenseModal} setModal={setLicenseModal}> <ListMiniCards setLicenseModal={setLicenseModal} licenceInput={licenceInput} pricePeach={pricePeach} /> </Modal>}


            <section className="flex flex-wrap p-3">

                <button onClick={() => { setModal(true) }} className={`flex p-1 pr-3 text-white rounded-full font-bold items-center transition-all duration-300 bg-darkPink mr-5`}>
                    <div className={`rounded-full w-9 h-9 flex justify-center items-center mr-2 transition-all duration-300 text-darkPink bg-primarypeach`}>
                        <Icons.pencil />
                    </div>
                    <div className="text-lg">Create Crew</div>
                </button>

                <button onClick={() => setLicenseModal(licenceInput)} className={`flex p-1 pr-3 text-white rounded-full font-bold items-center transition-all duration-300 bg-darkTeal`}>
                    <div className={`rounded-full w-9 h-9 flex justify-center items-center mr-2 transition-all duration-300 text-darkTeal bg-primaryskyblue`}>
                        <Icons.group />
                    </div>
                    <div className="text-lg">Add Licenses</div>
                </button>


            </section>
            <div className="flex flex-wrap p-5">

                {listItems?.map(element => <CrewCard icon={arrayIcon} licenseModal={licenseModal} setReloadData={setReloadData} setModalEdit={setModalEdit} nameCrew={element.name} {...element} />)}

            </div>
        </div>
    )
}