import React from 'react';
import start1 from "../../assets/vector/start1.svg"
import start2 from "../../assets/vector/start2.svg"
import start3 from "../../assets/vector/start3.svg"
import start4 from "../../assets/vector/start4.svg"
import start5 from "../../assets/vector/start5.svg"
export default function FilterRarityTractor({ handleFilterStartTractor, selectStartTractor }) {
    return <div>
        <h3 className="pb-2 text-lg text-white"> <strong>Filter by Tractor Rarity:</strong>  </h3>
        <div className="flex">
            <button onClick={() => { handleFilterStartTractor("all", 0) }} className={`${selectStartTractor == 0 ? "bg-primaryDark" : "bg-background"} w-10 rounded-md text-white h-9  ml-2 active:scale-90`}>All</button>
            <img onClick={() => { handleFilterStartTractor("1", 1) }} src={start1} alt="start-1" className={`${selectStartTractor == 1 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} />
            <img onClick={() => { handleFilterStartTractor("2", 2) }} src={start2} alt="start-2" className={`${selectStartTractor == 2 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} />
            <img onClick={() => { handleFilterStartTractor("3", 3) }} src={start3} alt="start-3" className={`${selectStartTractor == 3 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} />
            <img onClick={() => { handleFilterStartTractor("4", 4) }} src={start4} alt="start-4" className={`${selectStartTractor == 4 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} />
            <img onClick={() => { handleFilterStartTractor("5", 5) }} src={start5} alt="start-5" className={`${selectStartTractor == 5 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} />
        </div>
    </div>;
}
