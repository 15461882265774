import React, { useState, useEffect, useContext, useRef } from 'react'
import UserContext from '../context/userContext/UserContext';
import contractAddress from "../utils/Contract.json"
import { useWeb3React } from '@web3-react/core'
export default function useHarvesting({ modal }) {

    const { library } = useWeb3React()

    const [step, setStep] = useState(1)
    const [nameCrew, setNameCrew] = useState("")
    const [newNameCrew, setNewNameCrew] = useState("")
    const [typeResult, setTypeResult] = useState("failed")
    const [selectFarmer, setSelectFarmer] = useState(null)
    const [waifus, setWaifus] = useState(null)
    const [allWaifus, setAllWaifus] = useState([])
    const [tractor, setTractor] = useState(null)
    const [allTractor, setAllTractor] = useState([])
    const [selectStartWaifu, setSelectStartWaifu] = useState(0)
    const [selectStartTractor, setSelectStartTractor] = useState(0)
    const [loading, setLoading] = useState(false)

    const [spaceLeft, setSpaceLeft] = useState(null)
    const [availableTractors, setAvailableTractors] = useState([null])

    const [needWaifu, setNeedWaifu] = useState({ num: 0, den: 0 })
    const [needTractor, setNeedTractor] = useState(0)

    const [selectWaifu, setSelectWaifu] = useState([])
    const [selectTractor, setSelectTractor] = useState([])

    const [autoSelectState, setAutoSelectState] = useState(false);

    const [loadingHarverst, setLoadingHarvest] = useState(-1)
    const [errorHarvest, setErrorHarvest] = useState(null)

    const [blockLeft, setBlockLeft] = useState("")

    const [resumeWaifu, setResumeWaifu] = useState(null)
    const [resumeRarity, setResumeRarity] = useState(null)
    const [resumeDurability, setResumeDurability] = useState(null)
    const [originWP, setOriginWP] = useState([0, 0, 0, 0, 0, 0])
    const [currentTractors, setCurrentTractors] = useState([])
    const [currentWaifus, setCurrentWaifus] = useState([])

    const [tractorVinculate, setTractorVinculate] = useState([])
    const [waifuVinculate, setWaifuVinculate] = useState([])



    const { contract, account, chainId } = useContext(UserContext)

    //Reset all
    useEffect(() => {
        if (!modal) {
            setTypeResult("failed")
            setStep(1)
            selectFarmer(null)
            setTractor([])
            setWaifus([])
        }
    }, [modal])




    useEffect(() => {
        if (contract && account) {
            contract.contractWaifu.methods.getFarmerIdsOf(account).call().then(result => {
                const getAllWaifus = []
                let waifusAvailable = []
                result.map(element => { if (element !== "1000000000000000000") return getAllWaifus.push(contract.contractWaifu.methods.getFarmer(element).call().then(result => waifusAvailable.push(result))) })
                Promise.all(getAllWaifus).then(result => {
                    let listAllWaifus = waifusAvailable.reverse().map(element => element && ({ adn: '0'.repeat(61 - element.dna.length) + element.dna, ...element, waifuPower: Math.max(...element.waifuPowers) }))


                    listAllWaifus.sort((a, b) => {
                        if (a.waifuPower > b.waifuPower) return -1;
                        if (a.waifuPower < b.waifuPower) return 1;
                        return 0;
                    })

                    //Filter waifus sick, market, crew
                    let petititonFarmerToFormation = []
                    let listWaifusFilter = []
                    listAllWaifus.map(element => {

                        let timeNow = new Date()
                        let sickLeft = element.sickUntil - (timeNow.getTime() / 1000)
                        let sleepLeft = element.availableFrom - (timeNow.getTime() / 1000)

                        petititonFarmerToFormation.push(contract.ContractCrew.methods.farmerToFormation(parseInt(element.id))
                            .call({ from: account })
                            .then(result => {

                                if (result === "0" && sickLeft <= 0 && sleepLeft <= 0 && parseInt(element.durability) > 1000 && !element.forSale) listWaifusFilter.push(element)
                            })
                            .catch(err => console.log(err)))



                    })
                    Promise.all(petititonFarmerToFormation).then(result => {
                        setWaifus(listWaifusFilter)
                        setAllWaifus(listWaifusFilter)
                        setWaifuVinculate(listAllWaifus)
                    }).catch(err => console.log(err))


                }).catch(err => { console.log(err); setWaifus([]); setAllWaifus([]); })
            })
        }
    }, [contract, account])




    useEffect(() => {
        if (contract && account) {
            contract.contractTractor.methods.getTractorIdsOf(account).call().then(result => {
                const getAllTractor = []
                let tractorAvailable = []
                result.map(element => { if (element !== "1000000000000000000") return getAllTractor.push(contract.contractTractor.methods.getAvailability(element).call().then(result => result && contract.contractTractor.methods.getTractor(element).call().then(result => tractorAvailable.push(result)))) })
                Promise.all(getAllTractor).then(result => {


                    tractorAvailable.sort((a, b) => {
                        if (parseInt(a.level) > parseInt(b.level)) return -1;
                        if (parseInt(a.level) < parseInt(b.level)) return 1;
                        return 0;
                    })


                    //Filter damanger tractor
                    let petititonTractorToFormation = []
                    let listTractorAvailable = []
                    tractorAvailable.map(async element => {
                        let timeNow = new Date()
                        let brokenLeft = element.brokenUntil - (timeNow.getTime() / 1000)
                        let sleepLeft = element.availableFrom - (timeNow.getTime() / 1000)

                        petititonTractorToFormation.push(contract.ContractCrew.methods.tractorToFormation(parseInt(element.id))
                            .call({ from: account })
                            .then(result => {
                                if (result === "0" && sleepLeft <= 0 && brokenLeft <= 0 && !element.forSale && parseInt(element.durability) > 1000) listTractorAvailable.push(element)
                            })
                            .catch(err => console.log(err)))

                    })

                    Promise.all(petititonTractorToFormation).then(result => {
                        setTractorVinculate(tractorAvailable)
                        setAllTractor(listTractorAvailable);
                        setTractor(listTractorAvailable)
                    }).catch(err => console.log(err))

                }).catch(err => { setAllTractor([]); setTractor([]) })
            })
        }
    }, [contract, account])


    useEffect(() => {

        if (waifus && tractor) {


            if (modal.edit) {

                contract.ContractCrew.methods.getNFTIds(parseInt(modal.id)).call({ from: account })
                    .then(result => { console.log("testing", result); setSpaceLeft(parseInt(modal.space) - result[1].length); setCurrentWaifus(result[1]); setCurrentTractors(result[0]); setAvailableTractors(result[0].length); setNameCrew(modal.name) })




                /* contract.ContractCrew.methods.getTractorIds(parseInt(modal.id)).call({ from: account })
                    .then(result => setAvailableTractors(result.length)) */

            }

        }

    }, [waifus, tractor])

    useEffect(() => {

        let interval;
        if (step === 4 && modal.edit) {

            setLoading(true)
            contract.ContractCrew.methods.updateFormation(parseInt(modal.id), selectWaifu, selectTractor, true, nameCrew).send({ from: account, })
                .then(async result => setTypeResult("success"))
                .finally(() => setLoading(false))


        } else if (step === 4) {

            setLoading(true)
            contract.ContractCrew.methods.createCrew(nameCrew, selectWaifu, selectTractor).send({ from: account, })
                .then(async result => setTypeResult("success"))
                .finally(() => setLoading(false))
                .catch(err => setTypeResult("failed"))
        }


    }, [step])

    const selectOneFarmCard = (farmerId, wp) => {


        setStep(step + 1);
        setSelectFarmer(farmerId);
        setNeedWaifu({ num: 0, den: wp })
        setSelectWaifu([])
        setSelectTractor([])
        setNeedTractor(0)




    }

    const handleSelectWaifu = (id) => {
        let tempWaifus;
        let findWaifuObject = waifus.find(element => element.id === id)
        let findWaifu = selectWaifu.find(element => element === parseInt(id))
        if (findWaifu || findWaifu === 0) {
            setNeedWaifu(prevState => ({ ...prevState, num: prevState.num - parseInt(findWaifuObject.waifuPowers[(modal - 1) / 3]) }))
            tempWaifus = selectWaifu.filter(element => element !== parseInt(id))
        }
        else {
            setNeedWaifu(prevState => ({ ...prevState, num: prevState.num + parseInt(findWaifuObject.waifuPowers[(modal - 1) / 3]) }))
            tempWaifus = [...selectWaifu, parseInt(id)]
        }
        setSelectWaifu(tempWaifus)
    }

    const handleSelectTractor = (id) => {



        let tempTractors;

        let findTractorObject = tractor.find(element => element.id === id)
        let findTractor = selectTractor.find(element => element === parseInt(id))
        if (findTractor) {
            setNeedTractor(prevState => prevState - parseInt(findTractorObject.level))
            tempTractors = selectTractor.filter(element => element !== parseInt(id))
        }
        else {
            setNeedTractor(prevState => prevState + parseInt(findTractorObject.level))
            tempTractors = [...selectTractor, parseInt(id)]
        }

        setSelectTractor(tempTractors)
    }




    const handleFilterStartWaifu = (value, index) => {

        setSelectStartWaifu(value)

        if (value === "all") return setWaifus(allWaifus)
        else if (value === "1") setWaifus(allWaifus.filter(element => parseInt(element.waifuPower) <= 49))
        else if (value === "2") setWaifus(allWaifus.filter(element => parseInt(element.waifuPower) <= 99 && parseInt(element.waifuPower) > 49))
        else if (value === "3") setWaifus(allWaifus.filter(element => parseInt(element.waifuPower) <= 149 && parseInt(element.waifuPower) > 99))
        else if (value === "4") setWaifus(allWaifus.filter(element => parseInt(element.waifuPower) <= 199 && parseInt(element.waifuPower) > 149))
        else if (value === "5") setWaifus(allWaifus.filter(element => parseInt(element.waifuPower) <= 250 && parseInt(element.waifuPower) > 199))

    }


    const handleFilterStartTractor = (value, index) => {
        setSelectStartTractor(value)
        if (value === "all") return setTractor(allTractor)
        setTractor(allTractor.filter(element => element.level === value))
    }

    const autoSelect = (selectType) => {
        if (selectType === 'waifus') {
            let a = needWaifu.num || 0;
            let b = selectWaifu || [];
            let c = waifus.filter((e) => !selectWaifu.includes(parseInt(e.id)));
            for (let val of c) {
                if (a >= needWaifu.den) { break };
                a += parseInt(val.waifuPower);
                b.push(parseInt(val.id));
            };
            setNeedWaifu({ num: a, den: needWaifu.den });
            setSelectWaifu(b);
            setAutoSelectState(!autoSelectState);
        } else {
            let a = needTractor || 0;
            let b = selectTractor || [];
            let c = tractor.filter((e) => !selectTractor.includes(parseInt(e.id)));
            for (const [i, val] of c.entries()) {
                if ((!modal.edit && b.length >= 10) || (modal.edit && (b.length + availableTractors) >= 10) || a >= selectWaifu.length) { break };
                a += parseInt(val.level);
                b.push(parseInt(val.id));
            };
            setNeedTractor(a);
            setSelectTractor(b);
            setAutoSelectState(!autoSelectState);
        }
    }



    const getWaifuPower = () => {



        if (modal.edit) {

            let arrayWaifuPowerSelected = [0, 0, 0, 0, 0, 0]

            selectWaifu.map(element => {

                let findWaifu = allWaifus.find(elementTwo => elementTwo.id === element.toString())

                if (findWaifu) findWaifu.waifuPowers.map((elementTwo, index) => arrayWaifuPowerSelected[index] += parseInt(elementTwo))

            })

            setResumeWaifu(arrayWaifuPowerSelected.map((x, i) => x + originWP[i]))

        } else {


            let arrayWaifuPowerSelected = [0, 0, 0, 0, 0, 0]


            selectWaifu.map(element => {

                let findWaifu = allWaifus.find(elementTwo => elementTwo.id === element.toString())

                if (findWaifu) findWaifu.waifuPowers.map((elementTwo, index) => arrayWaifuPowerSelected[index] += parseInt(elementTwo))

            })

            setResumeWaifu(arrayWaifuPowerSelected)

        }
    }

    const getRarity = () => {

        if (modal.edit) {

            const rarities = [0, 0, 0, 0, 0]

            let allTractorCalculate = [...selectTractor, ...currentTractors]


            allTractorCalculate.forEach(element => {
                const findTractor = tractorVinculate.find(elementTwo => elementTwo.id === element.toString())

                if (findTractor) rarities[findTractor.level - 1]++

            })

            let mode = 0
            let max = rarities[0]

            for (let i = 1; i < 5; i++) {
                if (rarities[i] > max) {
                    max = rarities[i]
                    mode = i
                }
            }

            setResumeRarity(mode)

        } else {


            const rarities = [0, 0, 0, 0, 0]

            selectTractor.forEach(element => {
                const findTractor = allTractor.find(elementTwo => elementTwo.id === element.toString())

                if (findTractor) rarities[findTractor.level - 1]++
            })

            let mode = 0
            let max = rarities[0]

            for (let i = 1; i < 5; i++) {
                if (rarities[i] > max) {
                    max = rarities[i]
                    mode = i
                }
            }

            setResumeRarity(mode)
        }
    }

    const getDurability = () => {

        if (modal.edit) {

            let allDurabilityTractor = 0;
            let allDurabilityWaifus = 0;

            let allTractorsAcumulate = [...selectTractor]
            allTractorsAcumulate.forEach(element => {
                const findTractor = tractorVinculate.find(elementTwo => elementTwo.id === element.toString())

                if (findTractor) allDurabilityTractor += parseInt(findTractor.durability)
            })

            let allWaifusAcumulate = [...selectWaifu]
            allWaifusAcumulate.map(element => {

                let findWaifu = waifuVinculate.find(elementTwo => elementTwo.id === element.toString())
                // TODO: check this pls
                if (findWaifu && findWaifu.durability > 100000) allDurabilityWaifus += parseInt(
                    (
                      modal.durability * (
                        2 * currentWaifus.length +
                        3 * currentTractors.length
                      ) +
                      allDurabilityTractor * 3
                    ) /
                    (
                      2 * currentWaifus.length +
                      3 * currentTractors.length +
                      3 * selectTractor.length
                    )
                  )
                else if (findWaifu) allDurabilityWaifus += parseInt(findWaifu.durability)

            })

            let numCalculateNew = parseInt(modal.durability) * (currentTractors.length * 3 + currentWaifus.length * 2)

            let numCalculate = numCalculateNew + (allDurabilityTractor * 3) + (allDurabilityWaifus * 2)
            let denCalculate = (selectTractor.length + currentTractors.length) * 3 + (selectWaifu.length + currentWaifus.length) * 2


            setResumeDurability(parseInt(numCalculate / denCalculate))

        } else {

            let allDurabilityTractor = 0;
            let allDurabilityWaifus = 0;

            selectTractor.forEach(element => {
                const findTractor = tractorVinculate.find(elementTwo => elementTwo.id === element.toString())

                if (findTractor) allDurabilityTractor += parseInt(findTractor.durability)
            })

            selectWaifu.map(element => {

                let findWaifu = waifuVinculate.find(elementTwo => elementTwo.id === element.toString())
                if (findWaifu && findWaifu.durability > 100000) allDurabilityWaifus += parseInt(allDurabilityTractor / selectTractor.length)
                else if (findWaifu) allDurabilityWaifus += parseInt(findWaifu.durability)

            })

            let numCalculate = (allDurabilityTractor * 3) + (allDurabilityWaifus * 2)
            let denCalculate = selectTractor.length * 3 + selectWaifu.length * 2

            setResumeDurability(parseInt(numCalculate / denCalculate))
        }

    }


    useEffect(() => { if (modal.id) contract.ContractCrew.methods.getWPAndHealthParams(modal.id).call({ from: account, }).then(result => setOriginWP(result.wp.map(x => parseInt(x)))).catch(err => console.log("testing2", err)) }, [])


    useEffect(() => {
        getWaifuPower()
        getDurability()
    }, [selectWaifu, originWP])
    useEffect(() => {
        getRarity()
        getDurability()
    }, [selectTractor, currentTractors, tractorVinculate])

    return { resumeDurability, resumeRarity, resumeWaifu, availableTractors, spaceLeft, blockLeft, errorHarvest, loadingHarverst, autoSelectState, autoSelect, selectStartWaifu, selectStartTractor, handleFilterStartWaifu, handleFilterStartTractor, nameCrew, setNameCrew, newNameCrew, setNewNameCrew, selectTractor, needTractor, selectWaifu, needWaifu, selectOneFarmCard, handleSelectWaifu, handleSelectTractor, step, setStep, waifus, tractor, loading, setSelectFarmer, typeResult }
}
