import React, { useEffect, useState, useContext } from "react"
import CrewCards from "../CrewCard"
import WaifuCard from "../elements/WaifuCardItem"
import TractorCardItem from "../elements/TractorCardItem"
import FilterRarityWaifu from "../elements/FilterRarityWaifu"
import FilterRarityTractor from "../elements/FilterRarityTractor"
import { Swiper, SwiperSlide } from "swiper/react"
import harvestingLoading from "../../assets/img/crew.gif"
import harvestingSuccess from "../../assets/img/harvestNo.png"
import harvestingFailed from "../../assets/img/harvestYes.png"
import CrewsWizzardEdit from "./CrewsWizzardEdit"
import useCrews from "../../hooks/useCrews"
import UserContext from '../../context/userContext/UserContext'
import harvestingValues from "../../utils/Harvesting.json"
//import loadIcon from "../../assets/img/loadingIcon.webp";
import Loader from "../elements/ModalX/loader"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"

import "../../assets/css/swiper.css"


import cottonIcon from "../../assets/img/claimIcons/cottonIcon.svg";
import woolIcon from "../../assets/img/claimIcons/woolIcon.svg";
import leatherIcon from "../../assets/img/claimIcons/leatherIcon.svg";
import leaveIcon from "../../assets/img/claimIcons/leaveIcon.svg";
import flaxIcon from "../../assets/img/claimIcons/flaxIcon.svg";
import silkIcon from "../../assets/img/claimIcons/silkIcon.svg";

export default function FleetsModal({
    setModal,
    modal,
}) {
    const {
        errorHarvest,
        errorHarvestCall,
        loadingHarverst,
        autoSelectState,
        autoSelect,
        selectStartTractor,
        selectStartWaifu,
        handleFilterStartWaifu,
        handleFilterStartTractor,
        needTractor,
        selectWaifu,
        selectTractor,
        handleSelectWaifu,
        handleSelectTractor,
        setStep,
        step,
        waifus,
        tractor,
        loading,
        nameCrew,
        setNameCrew,
        setSelectFarmer,
        typeResult,
        selectOneFarmCard,
        needWaifu,
        blockLeft,
        spaceLeft,
        availableTractors,
        resumeRarity,
        resumeWaifu,
        resumeDurability
    } = useCrews({ modal })


    const { contract, account, chainId } = useContext(UserContext)
    const nextStep = () => {

        if (
            (step === 1 && selectWaifu.length > 0) ||
            (needTractor >= parseInt(selectWaifu.length - spaceLeft) && step === 2) ||
            (nameCrew.trim().length && step === 3) || modal.edit
        )
            setStep(step + 1)
    }


    const [arrayIcon] = useState([cottonIcon, woolIcon, leatherIcon, leaveIcon, flaxIcon, silkIcon])

    const disableButton = () => {
        if (step === 1 && (selectWaifu.length < 1 && !modal.edit)) return true
        else if (needTractor < parseInt(selectWaifu.length - spaceLeft) && step === 2) return true
        else if (!nameCrew.length && step === 3) return true
        return false
    }

    useEffect(() => {

        if ((step === 2 || step === 3), document) {

            let documentTest = document.getElementsByClassName("swiper-wrapper")

            for (var i = 0; i < documentTest.length; i++) {
                documentTest[i].className += " flex";
            }

        }


    }, [step, document])




    return (
        <div className="relative p-2 px-6 rounded-md w-screen md:w-[80vw]">
            <CrewsWizzardEdit step={step} />

            {step === 1 && (
                <>
                    <div className="flex justify-end items-center md:items-end mb-5 flex-col md:flex-row">
                        <div>
                            <FilterRarityWaifu
                                selectStartWaifu={selectStartWaifu}
                                handleFilterStartWaifu={handleFilterStartWaifu}
                            />
                        </div>
                    </div>

                    <div className="bg-primary p-5 overflow-x-auto overflow-y-hidden flex w-full rounded-lg ">
                        {waifus ? (
                            <>
                                {waifus.length > 0 ? (
                                    <Swiper
                                        breakpoints={{
                                            1250: { slidesPerView: 4 },
                                            900: { slidesPerView: 3 },
                                            600: { slidesPerView: 2 },
                                            200: { slidesPerView: 1 },
                                        }}
                                        spaceBetween={10}
                                        pagination={{ clickable: true }}
                                        className="mySwiper"
                                    >
                                        {waifus.map(element => (
                                            <SwiperSlide>

                                                <WaifuCard
                                                    autoSelectState={autoSelectState}
                                                    selectStartWaifu={selectStartWaifu}
                                                    handleSelectWaifu={handleSelectWaifu}
                                                    element={element}
                                                    selectWaifu={selectWaifu}
                                                    icon={arrayIcon}
                                                    harvesting={"Select"}
                                                    editingCrew={true}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                ) : (
                                    <h3 className="text-[#AA2E74] text-center w-full text-md font-bold ">

                                        You have no waifus available, mint some waifus or check the
                                        licenses
                                    </h3>
                                )}
                            </>
                        ) : (
                            <div className="h-80 flex justify-center items-center w-full">
                                <Loader />
                            </div>
                        )}
                    </div>
                </>
            )}

            {step === 2 && (
                <>
                    <div className="flex justify-between items-center md:items-end mb-5 flex-col md:flex-row">
                        <div className="mb-2 md:mb-0">
                            <div className="rounded-md bg-pink-200 bg-opacity-70 p-2 flex items-center">
                                <div className="flex flex-col">
                                    <div className="text-pink-700 mr-1 text-center md:text-left">
                                        Max Tractor:
                                        <span className="font-bold">{selectTractor.length + availableTractors}/10</span>
                                    </div>
                                    <div className="text-pink-700 mr-1 text-center md:text-left">
                                        Tractor Seats Required:
                                        <span className="font-bold">
                                            {needTractor}/{parseInt(selectWaifu.length - spaceLeft) <= 0 ? "0" : parseInt(selectWaifu.length - spaceLeft)}
                                        </span>
                                    </div>
                                </div>
                                {/* <button
                                    className="text-white font-bold rounded-full p-1 px-3 bg-darkPink hidden md:block"
                                    onClick={() => autoSelect("tractors")}
                                >
                                    Auto Select
                                </button> */}
                            </div>
                            {/*  <div className="flex justify-center">
                                <button
                                    className="text-white font-bold rounded-b-lg pb-1 w-4/5 bg-darkPink block md:hidden"
                                    onClick={() => autoSelect("tractors")}
                                >
                                    Auto Select
                                </button>
                            </div> */}
                        </div>
                        <div>
                            <FilterRarityTractor
                                selectStartTractor={selectStartTractor}
                                handleFilterStartTractor={handleFilterStartTractor}
                            />
                        </div>
                    </div>

                    <div className="bg-primary p-2 overflow-x-auto overflow-y-hidden flex w-full rounded-lg ">
                        {tractor ? (
                            <>
                                {tractor.length > 0 ? (
                                    <Swiper
                                        breakpoints={{
                                            1250: { slidesPerView: 4 },
                                            900: { slidesPerView: 3 },
                                            600: { slidesPerView: 2 },
                                            200: { slidesPerView: 1 },
                                        }}
                                        spaceBetween={10}
                                        pagination={{ clickable: true }}
                                        className="mySwiper"
                                    >
                                        {tractor.map(element => (
                                            <SwiperSlide>

                                                <TractorCardItem
                                                    autoSelectState={autoSelectState}
                                                    selectStartTractor={selectStartTractor}
                                                    handleSelectTractor={handleSelectTractor}
                                                    element={element}
                                                    selectTractor={selectTractor}
                                                    harvesting={"Select"}
                                                    edit={selectTractor.length + availableTractors}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                ) : (
                                    <h3 className="text-[#AA2E74] text-center w-full text-2xl font-bold ">

                                        You have no tractors available, mint some tractors
                                    </h3>
                                )}
                            </>
                        ) : (
                            <div className="h-80 flex justify-center items-center w-full">
                                <Loader />
                            </div>
                        )}
                    </div>
                </>
            )}

            {
                step === 3 && (
                    <>
                        <div className="flex flex-col justify-center items-center text-center">
                            <h2 className="text-white font-bold text-xl">Name of your crew:</h2>
                            <input value={nameCrew} onChange={e => setNameCrew(e.target.value)} spellCheck="false" className="outline-none bg-primary rounded-xl px-2 py-1 font-semibold text-white text-center" />
                            <div className="-ml-24"><CrewCards durability={resumeDurability} icon={arrayIcon} wp={resumeWaifu} seats={parseInt(modal.space) + needTractor} nameCrew={nameCrew} rarity={resumeRarity} creating={true} /></div>
                        </div>
                    </>
                )
            }

            {step < 4 && (
                <section className="mt-3 flex justify-between">
                    <div>

                        {step >= 2 && (
                            <button
                                onClick={() => setStep(step - 1)}
                                className={`rounded-lg text-md bg-[#AA2E74] text-white px-3 py-1`}
                            >

                                <strong> Previous Step</strong>
                            </button>
                        )}
                    </div>
                    <div>

                        {step >= 1 && (
                            <button
                                onClick={nextStep}
                                className={`rounded-lg text-md text-white ${disableButton() ? "bg-[#7e7e7e]" : "bg-[#61BD4F]"
                                    } px-3 py-1 `}
                            >

                                <strong> Next Step </strong>
                            </button>
                        )}
                    </div>
                </section>
            )}

            {step === 4 && (
                <>
                    {loading ? (
                        <div className="flex flex-col text-center items-center w-full">

                            <img
                                src={harvestingLoading}
                                className="mx-auto rounded-md w-[30rem]"
                                alt="loading"
                            />

                        </div>
                    ) : (
                        <div className="bg-primary flex justify-between flex-col-reverse lg:flex-row rounded-lg w-full md:w-8/12 mx-auto">
                            <div className="p-2 relative">
                                <h2 className="text-[#AA2E74] text-2xl pb-4 text-left">

                                    <strong>
                                        {typeResult === "success"
                                            ? "SUCCESSFULL"
                                            : "FAILED!"}
                                    </strong>
                                </h2>
                                <p className="text-md pb-4 text-left font-semibold">
                                    <span>
                                        {typeResult === "success"
                                            ? "You crew was updated"
                                            : "Error while updating the crew"}
                                    </span>
                                </p>

                                <button
                                    onClick={() => {
                                        setStep(1)
                                        setModal(false)
                                    }}
                                    className="absolute bottom-0 left-0 rounded-lg text-md bg-[#AA2E74] text-white p-2 mb-2 ml-2"
                                >
                                    <strong> CLOSE </strong>
                                </button>
                            </div>
                            <div>
                                <img
                                    src={
                                        typeResult === "success"
                                            ? harvestingSuccess
                                            : harvestingFailed
                                    }
                                    alt="success"
                                    className="w-[15rem]  m-auto md:m-auto"
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
